import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';  
import { Storage } from '@ionic/storage';
import { ToastController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import {ServiciosService } from '../servicios.service';
//import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthenticationService {

  authState = new BehaviorSubject(false);
    url:any = "/login";

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
      private servicios: ServiciosService,
    public toastController: ToastController,
    // private activatedRoute: ActivatedRouteSnapshot
   // public Rnext: ActivatedRouteSnapshot, public Rstate: RouterStateSnapshot
  ) {
        
        
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }
    
    getURL()
    {
        return this.url;
    }
    
    setURL(U)
    {
        this.url = U;
    }

  ifLoggedIn() {
      
      //console.log(this.activatedRoute.snapshot);
      
    this.storage.get('USER_INFO').then((response) => {
      if (response) {
          console.log("logged in");
        this.authState.next(true);
      }
    });
  }
    
    readUser()
    {
        console.log("buscando usuario...", window.location.pathname);
        this.setURL(window.location.pathname);
        this.storage.get('USER_INFO').then((response) => {
      if (response) {
          this.servicios.setUsuario(response);
         // this.usuario = response;
      }
    });
    }
        

  login(U) {
  
    this.storage.set('USER_INFO', U).then((response) => {
        this.servicios.setUsuario(response);
        console.log("login");
     // this.router.navigate(['catalogos']);
      this.authState.next(true);
    });
  }

   
        
    

  logout() {
    this.storage.remove('USER_INFO').then(() => {
      this.router.navigate(['login']);
      this.authState.next(false);
    });
  }

  isAuthenticated() {
    return this.authState.value;
  }



}
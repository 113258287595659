import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import {CurrencyPipe} from '@angular/common';
import { DatePipe } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-GT';

registerLocaleData(localeEs, 'es-GT');

import { IonicStorageModule } from '@ionic/storage';
import { AuthGuard } from './services/auth-guard.service';
import { AuthenticationService } from './services/Authentication.service';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


import { Camera } from '@ionic-native/camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FormsModule } from '@angular/forms';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
      // IonicPageModule.forChild(PEDIDOPage),
     BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
      IonicStorageModule.forRoot(),
       FormsModule,
       ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
     
    StatusBar,
    SplashScreen,
      CurrencyPipe,
      DatePipe,
      BarcodeScanner,
      AuthGuard,
    AuthenticationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      SocialSharing,
       { provide: LOCALE_ID, useValue: 'es-GT' } ,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
       Camera,
    File,
    WebView,
    FilePath,
    FileOpener
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

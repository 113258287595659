import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthGuard } from './services/auth-guard.service';
import { AuthenticationService } from './services/Authentication.service';
import {  Router } from '@angular/router'; 
import {ServiciosService } from './servicios.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
   
      {
      title: 'PEDIDOS',
      url: '/rep-semanal',
      icon: 'today'
    },
    {
      title: 'FACTURAS',
      url: '/facturas',
      icon: 'document-text'
    }
      ,
      {
      title: 'INVENTARIO',
      url: '/inventario',
      icon: 'file-tray-full'
    }
      ,
      {
      title: 'CATALOGO',
      url: '/catalogos',
      icon: 'images'
    },
      {
      title: 'PRODUCTOS',
      url: '/productos',
      icon: 'shirt'
    },
    
      {
      title: 'COMISIONES',
      url: '/comisiones',
      icon: 'happy'
    },
    
      {
      title: 'REPORTES',
      url: '/resumen',
      icon: 'bar-chart'
    },
      {
      title: 'CONTABILIDAD',
      url: '/contabilidad',
      icon: 'library'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
     private authenticationService: AuthenticationService,
     //private servicios: ServiciosService,
     private router: Router,
   //  private next: ActivatedRouteSnapshot, private state: RouterStateSnapshot
  ) {
    this.initializeApp();
        
       // this.USUARIO = this.authenticationService.getUser();
        //console.log("usuario:", this.authenticationService.getUser());
        this.authenticationService.readUser();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
        
        
          this.authenticationService.authState.subscribe(state => {
        if (state) {
            
          //return true;
            console.log("estado:",state, window.location.pathname); 
       //this.router.navigate([this.authenticationService.getURL]);
            
          this.router.navigate(['/rep-semanal']);
        } else {
          this.router.navigate(['login']);
        }
      });
        
        
         
         
        
        
    });
  }

    
    
    

    
  ngOnInit() {
      /*
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }*/
  }
    
  recargar()
  {
    window.location.reload();
    return false;
  }
    
    logoutUser(){
        console.log("chuta");
        //this.USUARIO = this.authenticationService.getUser();
    this.authenticationService.logout();
        
  }
    
    
}

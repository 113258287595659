import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class ServiciosService {
    private _READER : any  			=	new FileReader();

  constructor(public http: HttpClient, private storage: Storage) { }
  
//PARA SERVIDOR

//tinypik = "./src/timthumb.php?src=./uploads/";
//api = "./public/api/"; 
uploads = "https://sistema.tutto.gt/uploads/"; 
  

// PARA DEVELOPMENT
tinypik = "https://sistema.tutto.gt/src/timthumb.php?src=./uploads/";
//tinypik = "http://localhost/pedidos/src/timthumb.php?src=./uploads/";
api = "https://sistema.tutto.gt/public/api/"; 
//api = "http://localhost/pedidos/public/api/";
//uploads = "http://localhost/pedidos/uploads/";
  
imagen = "https://sistema.tutto.gt/FOTOS/";
 check="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA45JREFUeJzt2z2IHVUYxvHfeqMS2YjEpMhGUcEPLIyNrYhaaKeIKFj42dtIRNSUCoLFVgqCqAnYqIVdIOJXI6KihWIUbPwgiYhaGIXE3Wsxe3Gce+69Z86ZmTuL84fT3D3znvd5zsyZmXfOMjAw8H9mJbLfTtyAK3FhjeOa5jd8hS/wdxcDXo1XcRrjHrWf8bQWJ2IFB3GmB2Lntb1zNOzFaor4c/BSD8Qtai/MyH8fPtzqcxbrGNUx4JkeiFvUXlRMVEj8N4H+h2eZUL2GblS4V+V3heNHcQqboWAtM8Il2I23Ajnsw/uKdSvEETyEjVkDrOAj0+59shW8z8ya+egzAQ4EDvgeF7eYeBPEii+bELp8PB7ofF+7uWdTV/ykPRIKdrjS6YziAaivrEkTP8brkyDlU6F6qv+Av9rJPZs1vGf2greIn0I/HvVfl44nBm+bnJkf4wT2T4IFF4Mekzvzp3CL0hmwnQxoQvzN+Lr843YxoBXxbA8DWhMPOxKDlhnhNv8m+l0DMSesKR5vr0o8fq74Kil3gVW8WzrmLO5PyTTAGr6VvtqfxLV1BqxrwCo+CAy8iQfqDBygc/HUM2CW+CZMWIp44g1YJD7HhKWJJ86A8xSLUmxCdUzYb4niiTPg4YTEYkxYunjiDHguMcF5JvRCPHEG3JWRaMiE3ognzoAVvJaRcNmEXokn/i4wMl08qWvCY3omnnrPAbkm5LRWxFP/SXAZJswT/4TiPf/2OqLLpLwLdGnCPPGHSv3WoxVXSC2JdWHCPPFPVfp2bgDtmrDomt+QYUBTBZENxWenIw3FmxDzPp+locmKUNMm1CpmpNJ0SawpEzoRTzs1wVwTOhNPe0XRVBM6FU+7VeG6JnQunvbL4rEmLEU83XwXWGTC0sQz34Amt6DNMuGkPPHZE1j+MHK68rc9ChPGuYNssYEHFQXVWxUvLutmfKqOZE/gtz9Sgz1v+jH0QGqwjrjXdM7B3R8x3BkI9oblbYtdxLn43HTO16QG3IlfAwEP5mbaAiO8bDrXz3IDPxkIOsYruDQ3eENch2PCed5RN1j19D4fH+P6QN+xYqf2CcvZKLkDlyl2rId4E/doYNG+HD/qpsrTVPsUu3KFl7kCX/ZAWEw7houaFD/hAjyLP3sgMtR+waNq7gSvEnOL2427cZNip8auyOOaZlPxHyPH8Q7eVkzOwMDAwMDAwMBACv8AT3vzszxOfuYAAAAASUVORK5CYII=";
   sucursal:any;
   token = {"USUARIO":"","token":""};
   usuario:any;
   private permisos:any = {PAGOS:false,PRODUCTOS:false,INVENTARIO:false,FACTURACION:false,ANULACIONES:false};
  colores:any[];
  
  getTinypikURL()
  {
    return this.tinypik;
  }

  setColores(C)
  {
    this.colores= C;
  }

  getColores()
  {
    return this.colores;
  }
  
  getApi()
  {
  return this.api;
  }
    
  getPermisos()
  {
    return this.permisos;
  }

  setPermisos(P)
  {
    this.permisos = P;
  }
    
    
     handleImageSelection2(event : any) : Observable<any>
   {
      let file 		: any 		= event;
      let reader 		: any =	new FileReader() ;

      reader.readAsDataURL(file);
      return Observable.create((observer) => 
      {
         reader.onloadend = () =>
         {
            observer.next(reader.result);
            observer.complete();
         }
      });
   }

    
   handleImageSelection(event : any) : Observable<any>
   {
      let file 		: any 		= event.target.files[0];

      this._READER.readAsDataURL(file);
      return Observable.create((observer) => 
      {
         this._READER.onloadend = () =>
         {
            observer.next(this._READER.result);
            observer.complete();
         }
      });
   }

   isCorrectFileType(file)
   {
      return (/^(gif|jpg|jpeg|png)$/i).test(file);
   }

  /* uploadImageSelection2(file 		: string, descripcion : string,
                        mimeType 	: string) : Observable<any>
   {
      let headers 	: any		= new HttpHeaders({'Content-Type' : 'application/octet-stream'}),
          fileName  : any       = Date.now() + '.' + mimeType,
          options 	: any		= { "name" : fileName, "file" : file };

      return this.http.post(this._REMOTE_URI, JSON.stringify(options), headers);
   }*/
    
     uploadImageSelection(file 		: string, descripcion : string,
                        mimeType 	: string) : Observable<any> {
   
let u = this.getToken();
let p = {filename:"",file:"",descripcion:""};

var formData: any = new FormData();
  formData.append("credenciales", JSON.stringify(u) );
p.filename = Date.now() + '.' + mimeType;
p.file = file;
p.descripcion = descripcion;


    
 formData.append("datos", JSON.stringify(p)   );
    
return this.http.post(this.getApi()+"UPLOAD",formData );
  
  }
    
     uploadCatlogo(file : string, mimeType: string) : Observable<any> {
   
let u = this.getToken();
let p = {filename:"",file:""};

var formData: any = new FormData();
  formData.append("credenciales", JSON.stringify(u) );
p.filename = Date.now() + '.' + mimeType;
p.file = file;
    
 formData.append("datos", JSON.stringify(p)   );
    
return this.http.post(this.getApi()+"UPLOADCATALOGO",formData );
  
  }
    
    
    
 getCheck()
    {
        return this.check;
    }
    
    
    getImagenURL()
  {
  return this.imagen;
  }
    
    getUploadsURL()
  {
  return this.uploads;
  }
  
getEmpresaID()
    {
        return this.usuario.EMPRESA_ID;
    }

getEmpresa()
    {
        return this.usuario.DatosIniciales.EMPRESA;
    }
    
    getVendedores()
    {
        return this.usuario.DatosIniciales.VENDEDORES;
    }
    
    getPilotos()
    {
        return this.usuario.DatosIniciales.PILOTOS;
    }
    
    
  getToken()
  {
  return {"USUARIO":this.usuario.ID,"token":this.usuario.token, "EMPRESA_ID":this.usuario.EMPRESA_ID};
  }
    
    haySucursales()
    {
        return Array.isArray(this.usuario.SUCURSALES);
    }
    
    getSucursales()
    {
        return this.usuario.SUCURSALES;
    }
    
  getUsuario()
    {
        return this.usuario;
    }

    getUsuarioId()
    {
        return this.usuario.ID;
    }
    
  setUsuario(u)
    {
        this.usuario=u; 
    }
    
  setEmpresa(S)
    {
        this.usuario.EMPRESA_ID = S.ID;
        this.usuario.DatosIniciales.EMPRESA = S;
        this.storage.set('USER_INFO', this.usuario).then((response) => { 
    });
  
    }
    
  
}


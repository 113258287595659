import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'rep-semanal',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'rep-semanal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./rep-semanal/rep-semanal.module').then( m => m.RepSemanalPageModule)
   
  },
  {
    path: 'pedido/:id',
    loadChildren: () => import('./pedido/pedido.module').then( m => m.PEDIDOPageModule)
  },
    {
    path: 'pedido',
    loadChildren: () => import('./pedido/pedido.module').then( m => m.PEDIDOPageModule)
  },
  {
    path: 'asignar-piloto',
    loadChildren: () => import('./asignar-piloto/asignar-piloto.module').then( m => m.AsignarPilotoPageModule)
  },
  {
    path: 'asignar-guia',
    loadChildren: () => import('./asignar-guia/asignar-guia.module').then( m => m.AsignarGuiaPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./productos/productos.module').then( m => m.ProductosPageModule)
  },
  {
    path: 'producto',
    loadChildren: () => import('./producto/producto.module').then( m => m.ProductoPageModule)
  },
  {
    path: 'producto/:id',
    loadChildren: () => import('./producto/producto.module').then( m => m.ProductoPageModule)
  },
  {
    path: 'productos-modal',
    loadChildren: () => import('./productos-modal/productos-modal.module').then( m => m.ProductosModalPageModule)
  },
  {
    path: 'anulados',
    loadChildren: () => import('./anulados/anulados.module').then( m => m.AnuladosPageModule)
  },
  {
    path: 'inventario',
    loadChildren: () => import('./inventario/inventario.module').then( m => m.InventarioPageModule)
  },
  {
    path: 'ingreso',
    loadChildren: () => import('./ingreso/ingreso.module').then( m => m.IngresoPageModule)
  },
  {
    path: 'egreso',
    loadChildren: () => import('./egreso/egreso.module').then( m => m.EgresoPageModule)
  },
  {
    path: 'promocion',
    loadChildren: () => import('./promocion/promocion.module').then( m => m.PromocionPageModule)
  },
  {
    path: 'catalogo',
    loadChildren: () => import('./catalogo/catalogo.module').then( m => m.CatalogoPageModule)
  },
  {
    path: 'catalogo/:id',
    loadChildren: () => import('./catalogo/catalogo.module').then( m => m.CatalogoPageModule)
  },
  {
    path: 'catalogos',
    loadChildren: () => import('./catalogos/catalogos.module').then( m => m.CatalogosPageModule)
  },
  {
    path: 'upload',
    loadChildren: () => import('./upload/upload.module').then( m => m.UploadPageModule)
  },
  {
    path: 'pagos',
    loadChildren: () => import('./pagos/pagos.module').then( m => m.PagosPageModule)
  },
  {
    path: 'pago',
    loadChildren: () => import('./pago/pago.module').then( m => m.PagoPageModule)
  },
  {
    path: 'facturas',
    loadChildren: () => import('./facturas/facturas.module').then( m => m.FacturasPageModule)
  },
  {
    path: 'factura',
    loadChildren: () => import('./factura/factura.module').then( m => m.FacturaPageModule)
  },
  {
    path: 'editarfactura/:id',
    loadChildren: () => import('./factura/factura.module').then( m => m.FacturaPageModule)
  },
  {
    path: 'modal-factura',
    loadChildren: () => import('./modal-factura/modal-factura.module').then( m => m.ModalFacturaPageModule)
  },
    {
    path: 'movimientos-inventario/:tipo',
    loadChildren: () => import('./movimientos-inventario/movimientos-inventario.module').then( m => m.MovimientosInventarioPageModule)
  },
  {
    path: 'cardex/:id',
    loadChildren: () => import('./cardex/cardex.module').then( m => m.CardexPageModule)
  },
  {
    path: 'resumen',
    loadChildren: () => import('./resumen/resumen.module').then( m => m.ResumenPageModule)
  },  {
    path: 'comisiones',
    loadChildren: () => import('./comisiones/comisiones.module').then( m => m.ComisionesPageModule)
  },
  {
    path: 'contabilidad',
    loadChildren: () => import('./contabilidad/contabilidad.module').then( m => m.ContabilidadPageModule)
  }




    
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
